import * as React from "react"

// Gatsby expects a 404 page but I'd rather just rely on Netlify's
// one, so this is removed post-build by package.json build script
const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <h1>Page not found</h1>
    </main>
  )
}

export default NotFoundPage
